import { getStorage, ref, connectStorageEmulator, getDownloadURL} from "firebase/storage";
import { 
  getAuth,
  onAuthStateChanged
} from "firebase/auth";

const storage = getStorage();
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  // Point to the Storage emulator running on localhost.
  connectStorageEmulator(storage, "127.0.0.1", 9199);
} 

const localFolder = "/default_files/"

function getAuthState() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
}
function getReference(filename){
  return getAuthState().then(user => {
    if (user) {
      return getDownloadURL(ref(storage, filename));
    }
    return new Promise(function (res, _) { res(process.env.PUBLIC_URL + localFolder + filename)})
  })
}
export function getPictureUrl(picture_id){
  return getReference('images/thumbnails/' + picture_id + '.png')
}
export function getPictureTinyUrl(picture_id){
  return getReference('images/tiny/' + picture_id + '.png')
}
export function getPictureLargeUrl(picture_id){
  return getReference('images/' + picture_id + '.png')
}
export function getPictureLabelUrl(picture_id){
  return getReference('images/labels/' + picture_id + '.txt')
}

export function getTreeJsonUrl() {
  return getReference('family_tree.json')
}
export function getTreeOrderedJsonUrl() {
  return getReference('family_tree_ordered.json')
}
export function getPictureJsonUrl() {
  return getReference('picture_index.json')
}