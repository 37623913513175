import React from 'react';
import Navbar from './components/Navbar';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/auth/login';
import Logout from './pages/auth/logout';
// import Register from './pages/auth/register';
import Reset from './pages/auth/reset';
import FamilyTree from './pages/familyTree';
import Pictures from './pages/pictures';
import Picture from './pages/picture';
import People from './pages/people';
import Person from './pages/person';



  
function App() {
    return (
        <Router>
        <Navbar />
        <Routes>
            <Route
                path="/"
                element={<Navigate to="/family-tree" replace />}
            />
            <Route path='/login' element={<Login/>} />
            <Route path='/logout' element={<Logout/>} />
            {/* <Route path='/register' element={<Register/>} /> */}
            <Route path='/reset' element={<Reset/>} />
            <Route path='/family-tree' element={<FamilyTree />} />
            <Route path='/pictures' element={<Pictures/>} />
            <Route path='/picture' element={<Picture/>} />
            <Route path='/people' element={<People/>} />
            <Route path='/person' element={<Person/>} />
        </Routes>
        </Router>
    );
}
  
export default App;