import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, logout } from "../../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./login.css";

function Logout() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  function tree_redirect(){
    if (user) navigate("/family-tree");
  }

  return (
    <div className="login">
      <div className="login__container">
        <button
          className="login__btn"
          onClick={() => tree_redirect()}
        >
          Go to Family Tree
        </button>
        <button
          className="login__btn"
          onClick={() => logout()}
        >
          Logout
        </button>
      </div>
    </div>
  );
}
export default Logout;